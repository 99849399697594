<template>
    <v-main>
        <Header></Header>
        <div class="section9">
          <v-container class="justify-center">
            <v-card-title class="justify-center"   style="margin:5em 0px 5em 0px; word-break: normal;"  primary-title>
             <h2>
                Moftahk
             </h2>
         </v-card-title>
        </v-container>
             <v-container >
                 <div class="space" >

            <v-card  hover outlined raised color="rgba(139, 214, 255,0.2)">
                <v-row>
                  <v-col>
                      <v-card-title class="justify-center" >    
                      <v-img height="3em" width="4em" contain :src="require('../assets/icons/location.svg')"></v-img>
                      </v-card-title>
                      <v-card-title style="word-break:normal" class="justify-center">
                      Khartoum, Sudan
                      </v-card-title>
                          
                  </v-col>
                  <v-col>
                      <v-card-title class="justify-center" >
                        
                      <v-img height="3em" width="4em" contain :src="require('../assets/icons/Category.svg')"></v-img>
                      </v-card-title>
                      <v-card-title style="word-break:normal" class="justify-center" >
                        Mobile Development, Backend Development, Web development, Digital Marketing, Cloud Architecture and,  UI/UX Design
                      </v-card-title>
                  </v-col>
                  <v-col>
                      <v-card-title class="justify-center" >
                         <v-img height="3em" width="4em" contain :src="require('../assets/icons/industry.svg')"></v-img>
                      </v-card-title>
                         <v-card-title style="word-break:normal" class="justify-center">
                            Car Marketplace for rental and selling services
                         </v-card-title>
                  </v-col>
                </v-row>
            </v-card>
                 </div>
              </v-container>
            <!-- </v-row> -->
            
        <v-container>
        <v-container>
        <v-row>
            <v-col cols="12" xl="3" lg="3" md="4" sm="12"   >
                 <v-container >
                <div class="space" >
                    <v-img contain max-height="15em" max-width="15em" :src="require('../assets/portfolio/Moftahk-Logo 1.svg')"></v-img>
                </div>
                  </v-container>
            </v-col>

                





            <v-col>
                <v-container>    
                <v-row>
                <h2>About Moftahk</h2> 
                <div class="space">
                    <v-col>
                    <p>
                        Moftahk was established in 2022, with the aim of becoming Sudan’s fastest-growing car market mobile application for buyers and sellers across Sudan. <br>
                        In addition to buying and selling services , Moftahk provides a platform for renting cars and auto-motor vehicles by creating and facilitating a network between lessors and leaseholders
                    </p>

                    </v-col>
                </div>
                </v-row>
                </v-container>
            </v-col>
        </v-row>
        </v-container>
         <v-container>
                    
        <v-row>
            <v-col >
                <h2>
                    What The Code Effect Did:
                </h2>
                <div class="space">
                    <v-container>
                        

                <p>
                    Moftahk approached The Code Effect with the idea of the app in early 2022, since then, we’ve been working closely with Moftahk to bring their vision to life. After analyzing the client’s requirements thoroughly, The Code Effect’s team designed and developed a cross-platform mobile application that enables users to browse cars for Sale or Rent, and also post their own cars on the app. <br>
                   The Code Effect designed and developed the User Interface to be intuitive and aesthetically pleasing to increase the users’ time in the app, ultimately improving the client’s ROI. The app is available on both Android and iOS and is responsive to all screen sizes. A Content Management System and User Management System can be accessed through a customized Admin Panel where the admin can access full reports and statistics in real-time.
                 
                </p>
                    </v-container>
    </div>
    
            </v-col>
                          <v-col lg="4" md="12" sm="12">
                  <iOsMockup></iOsMockup>
              </v-col>

        </v-row>
         <v-container >
            <v-row>
              <!-- <v-col lg="6" md="12" sm="12">
                      <MockUp/>
              </v-col> -->
              <!-- <v-col lg="6" md="12" sm="12">
                  <iOsMockup></iOsMockup>
              </v-col> -->
            </v-row>
         </v-container>

          </v-container> 
        </v-container>
          
        <Footer></Footer>
        </div>

    </v-main>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer'
// import MockUp from '../components/MockUp'
import IOsMockup from '../components/iOsMockup.vue'

export default {
    name: 'Menasave',
    components:{
        Header,
        // MockUp,
        Footer,
        IOsMockup 
    },
    
 

}
</script>
